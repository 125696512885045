import React, { ComponentProps, useEffect, useState } from "react";
import { data, houses } from "../../data/houses";
import { Link, useParams } from "react-router-dom";
import Header from "../../components/Header";
import Item from "../../components/Item";
import { numberToMonetary } from "../../utils/monetary";
import Button from "../../components/Button";
import Gallery from "../../components/Gallery";
import { GoArrowLeft } from "react-icons/go";
import Availability from "../../components/Availability";
import { IoMdClose } from "react-icons/io";
import LotMap from "../../components/Map";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import { TransformWrapper } from "react-zoom-pan-pinch";
import { TransformComponent } from "react-zoom-pan-pinch";
import { ZoomControls } from "../../components/ZoomControls";

const useResponsive = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return {
    small: 640,
    medium: 768,
    large: 1024,
    xLarge: 1280,
    twoXLarge: 1536,
    width,
  };
};

export const House = () => {
  const params = useParams();
  const { width, large } = useResponsive();
  const id = params?.id;

  const house = houses.find((house) => house.id === id) || {
    title: "",
    size: "",
    houseSize: "",
    price: 0,
    infos: [],
    amount: {
      total: 0,
      available: 0,
    },
    mainInfo: {
      title: "",
      label: "",
    },
    images: {
      exterior: [],
      interior: [],
      gallery: [],
      sphere: "",
    },
  };

  const [selectedImage, setSelectedImage] = useState<string | null>(
    house.images?.exterior[0] || null
  );

  const [selectedUnit, setSelectedUnit] = useState<string | null>(null);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [is360ModalOpen, setIs360ModalOpen] = useState(false);
  return (
    <div className="relative text-white h-screen bg-black flex justify-center  flex-col">
      <div
        className="absolute inset-0 z-20 bg-cover bg-center h-screen max-lg:hidden transition-all duration-700"
        style={{
          backgroundImage: `url(${selectedImage || ""})`,
        }}
      />
      <div className="absolute inset-0 z-20 bg-black bg-opacity-50 max-lg:hidden" />
      <div className="absolute inset-0 z-30 bg-gradient-to-t from-black to-transparent lg:hidden" />
      <img
        src={selectedImage || ""}
        className="inset-0 z-20  lg:hidden min-h-[800px] object-cover"
        alt="background"
      />

      <Header
        {...(data.find(({ id }) => id === "header")?.data as ComponentProps<
          typeof Header
        >)}
      />
      <div className="flex items-center justify-center flex-col max-lg:-translate-y-[100px] gap-3 z-30">
        <div className="relative z-30 max-w-7xl mx-auto w-full px-4 lg:px-8 lg:py-12 flex flex-col gap-4 max-lg:order-2">
          <Link
            className="flex items-center gap-4 max-lg:hidden"
            to="/#selection"
          >
            <div className="size-10 bg-white rounded-full flex items-center justify-center text-black">
              <GoArrowLeft size={24} />
            </div>
            <p className="leading-none">Voltar para seleção de casas</p>
          </Link>
          <div className="flex gap-4 flex-col lg:w-min lg:min-w-max">
            <div className="card gap-3 flex max-lg:flex-wrap p-4 flex-col">
              <div className="w-full flex items-center gap-4 max-lg:border-b border-gray-light max-lg:pb-4">
                <p className="text-2xl lg:text-3xl ">{house?.title}</p>
                <Availability available={house.amount.available} />
              </div>
              <div className="flex gap-2">
                <Item title="Lote, M²" label={house.size} />
                <Item title="Casa, M²" label={house.houseSize} />
                <Item title="Preço" label={numberToMonetary(house.price)} />
              </div>
              <div className="lg:hidden w-full pt-3 gap-3 flex flex-col">
                <Button
                  className="w-full"
                  onClick={() => setIsDetailModalOpen(true)}
                >
                  Agendar uma chamada
                </Button>
                <Button
                  className="w-full"
                  onClick={() => setIs360ModalOpen(true)}
                  variant="primary"
                  outline
                >
                  Visualizar 360°
                </Button>
              </div>
            </div>
            <div className="card flex flex-col gap-6 max-lg:mb-6 p-4">
              <div className="flex flex-col lg:flex-row gap-6">
                <div>
                  <Item
                    title={house.mainInfo.title}
                    label={house.mainInfo.label}
                    labelClassName="lg:text-3xl"
                  />
                </div>
                <div className="grid grid-cols-3 gap-4">
                  {house?.infos?.map((info) => (
                    <Item
                      key={info.title}
                      title={info.title}
                      label={info.label}
                    />
                  ))}
                </div>
              </div>
              <div className="max-lg:hidden flex gap-4">
                <Button onClick={() => setIsDetailModalOpen(true)}>
                  Agendar uma chamada
                </Button>
                <Button
                  onClick={() => setIs360ModalOpen(true)}
                  variant="primary"
                  outline
                >
                  Visualizar 360°
                </Button>
              </div>
            </div>
          </div>
        </div>
        <footer
          className="z-30 lg:absolute lg:bottom-0 w-full max-w-7xl mx-auto px-8 lg:py-12 max-lg:order-1
        "
        >
          <div className="flex gap-2 lg:gap-4 flex-nowrap overflow-x-auto max-lg:pb-3">
            <Gallery
              title="Exterior"
              images={house.images?.exterior || []}
              onClick={setSelectedImage}
              selectedImage={selectedImage || ""}
            />

            <Gallery
              title="Interior"
              images={house.images?.interior || []}
              onClick={setSelectedImage}
              selectedImage={selectedImage || ""}
            />
            <Gallery
              title="Galeria"
              images={house.images?.gallery || []}
              onClick={setSelectedImage}
              selectedImage={selectedImage || ""}
            />
          </div>
        </footer>
      </div>
      {isDetailModalOpen && (
        <div className="w-full h-screen bg-black fixed top-0 left-0 right-0 bottom-0 z-50 flex justify-center items-center bg-opacity-20">
          <div className="bg-white w-full h-full relative rounded-2xl flex flex-col justify-between">
            <IoMdClose
              size={32}
              className="absolute top-4 right-4 text-black cursor-pointer z-50"
              onClick={() => setIsDetailModalOpen(false)}
            />
            <div className="flex flex-col gap-4 overflow-x-auto h-full grow justify-between relative">
              <TransformWrapper
                minScale={0.3}
                initialScale={0.8}
                centerOnInit={true}
                disabled={width > large}
              >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <>
                    <h2 className="title-text text-black text-center pt-10">
                      Escolha sua casa
                    </h2>
                    <div className="flex justify-center items-center w-full h-full lg:mb-10">
                      <div className="lg:hidden flex items-center justify-center absolute bottom-2 right-2 z-20">
                        <ZoomControls />
                      </div>
                      <TransformComponent
                        wrapperClass="w-full h-full flex grow lg:items-center lg:justify-center"
                        contentClass="w-full h-full flex grow lg:items-center lg:justify-center"
                      >
                        <LotMap
                          setSelectedUnit={setSelectedUnit}
                          selectedUnit={selectedUnit}
                        />
                      </TransformComponent>
                    </div>
                  </>
                )}
              </TransformWrapper>
            </div>
            <div className="flex items-center justify-center w-full bg-black rounded-t-xl p-10 gap-4 flex-col lg:flex-row">
              <div className="flex flex-col lg:flex-row gap-4 items-center max-w-[1300px] w-full justify-between">
                {selectedUnit ? (
                  <p className="text-white text-center lg:text-xl flex gap-2">
                    <p>Unidade selecionada: </p>
                    <b className="lg:text-2xl">
                      {selectedUnit?.padStart(2, "0")}
                    </b>
                  </p>
                ) : (
                  <p className="text-white text-center lg:text-xl">
                    Selecione uma unidade
                  </p>
                )}

                <div className="flex gap-4 w-full lg:w-auto">
                  <Button variant="primary" className="w-full">
                    Agendar Chamada
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {is360ModalOpen && (
        <div className="w-full h-screen bg-black fixed top-0 left-0 right-0 bottom-0 z-50 flex justify-center items-center bg-opacity-20">
          <div className="bg-white lg:min-w-[600px] lg:w-[60%] lg:h-[70%] min-w-[300px] w-[90%] h-[90%] relative rounded-2xl overflow-hidden">
            <IoMdClose
              size={32}
              className="absolute top-4 right-4 text-black cursor-pointer z-50"
              onClick={() => setIs360ModalOpen(false)}
            />
            <ReactPhotoSphereViewer
              src={house.images.sphere}
              height={"100%"}
              width={"100%"}
              loadingTxt="Carregando..."
            />
          </div>
        </div>
      )}
    </div>
  );
};

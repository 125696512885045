import React from "react";

import Button from "../Button";
import { FiMapPin } from "react-icons/fi";

type Props = {
  title: string;
  description: string;
  primaryButtonLabel: string;
  primaryButtonLink: string;
  addressText: string;
};

const Hero = ({
  title,
  description,
  primaryButtonLabel,
  primaryButtonLink,
  addressText,
}: Props) => {
  return (
    <div
      className="relative bg-cover bg-center h-screen text-white flex items-center justify-center"
      //   style={{
      //     backgroundImage:
      //       "url('https://images.unsplash.com/photo-1652792997653-7e5897fb309a?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
      //   }}
    >
      {/* <div className="absolute inset-0 bg-black bg-opacity-70"></div> */}
      <div className="relative z-10 max-w-7xl mx-auto w-full px-4 lg:px-8 py-12 ">
        <main className="gap-10 items-start flex flex-col max-w-[700px]">
          <h1 className="text-4xl lg:text-[72px] font-semibold leading-[1.18] text-left">
            {title}
          </h1>
          <p className="text-xl text-left ">{description}</p>
          <div className="flex gap-4 lg:items-center max-lg:flex-col items-start">
            <Button>
              <a href={primaryButtonLink}>{primaryButtonLabel}</a>
            </Button>
            <div className="flex items-center gap-2">
              <div className="lg:size-10 size-8 border border-white rounded-full flex items-center justify-center text-white lg:text-2xl">
                <FiMapPin strokeWidth={1} />
              </div>
              <p className="max-lg:text-sm">{addressText}</p>
            </div>
          </div>
        </main>

        {/* <footer className="mt-16 space-y-4 text-sm">
          <div className="flex gap-12">
            <div>
              <strong>0.5 km</strong>
              <p>para lojas e pontos de ônibus</p>
            </div>
            <div>
              <strong>3 km</strong>
              <p>para o anel viário de Varsóvia</p>
            </div>
            <div>
              <strong>0.3 km</strong>
              <p>para florestas</p>
            </div>
          </div>
          <address>
            <strong>Józefów, Księdza Malinowskiego 38</strong> <br />
            Polônia
          </address>
        </footer> */}
      </div>
    </div>
  );
};

export default Hero;

import React from "react";

type Props = {
  title: string;
  description: string;
  imageUrl: string;
  imagePosition?: "left" | "right";
};

export const InfoCard = ({
  title,
  description,
  imageUrl,
  imagePosition = "right",
}: Props) => {
  return (
    <div className="grid max-w-7xl mx-auto w-full px-4 lg:px-8 py-10 lg:py-[100px] items-center gap-10 lg:grid-cols-2 grid-cols-1">
      <div
        data-order={imagePosition}
        className="text-white flex flex-col flex-1 gap-10 order-1 lg:data-[order=left]:order-1 z-10"
        // style={{
        //   order: imagePosition === "left" ? 1 : 0,
        // }}
      >
        <h2 className="title-text ">{title}</h2>
        <p className="description">{description}</p>
      </div>
      {/* <div className=""> */}
      <div
        data-order={imagePosition}
        className="lg:data-[order=right]:order-1 relative"
      >
        <div
          data-order={imagePosition}
          className="bg-[#043838] h-[300px] lg:h-[500px] lg:w-[600px] absolute -top-[20px] data-[order=right]:-right-[20px]  data-[order=left]:right-[20px] rounded-md"
        />
        <img
          src={imageUrl}
          alt={title}
          className="w-full h-[300px] lg:w-[600px] lg:h-[500px] object-cover z-10 relative rounded-md"
          // style={{
          //   order: imagePosition === "left" ? 0 : 1,
          // }}
        />
      </div>
    </div>
  );
};

export default InfoCard;

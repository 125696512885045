import React from "react";
import Item from "../Item";
import { numberToMonetary } from "../../utils/monetary";
import { Link } from "react-router-dom";
import { House } from "../../types/house";

type Props = {
  options: House[];
  title: string;
  htmlId: string;
};

const Selection = ({ options, title, htmlId }: Props) => {
  return (
    <div
      id={htmlId}
      className="bg-cover bg-center lg:h-screen text-white flex items-center justify-center relative max-lg:bg-black"
    >
      <div
        className="absolute inset-0 bg-cover bg-center h-screen max-lg:hidden"
        // style={{
        //   backgroundImage:
        //     "url('https://images.unsplash.com/photo-1652792997653-7e5897fb309a?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
        // }}
      />
      {/* <div className="absolute inset-0 bg-black bg-opacity-60" /> */}
      <div className="max-w-7xl mx-auto w-full px-4 lg:px-8 py-16 flex gap-4 items-start relative z-10">
        <div className="max-lg:w-full flex flex-col gap-4">
          <h2 className="text-2xl lg:text-4xl font-bold">{title}</h2>
          <div className="flex flex-col gap-4 max-lg:w-full">
            {options.map((option, index) => (
              <Link
                key={option.title}
                to={`/house/${option.id}`}
                data-index={index + 1}
                className={`flex card hover:bg-white gap-2 hover:text-black transition-all duration-300 lg:p-4 animate__animated animate__slideInLeft max-lg:w-full`}
                style={{
                  animationDelay: `${index * 200}ms`,
                }}
              >
                <img
                  className="size-28 object-cover rounded-lg lg:hidden"
                  src={option.images.exterior[0]}
                  alt={option.title}
                />
                <div className="flex max-lg:flex-wrap gap-2 max-lg:p-4 pl-1 max-lg:grid max-lg:grid-cols-2">
                  <Item title="Casa" label={option.title} />
                  <Item title="Lote, M²" label={option.size} />
                  <Item title="Casa, M²" label={option.houseSize} />
                  <Item title="Preço" label={numberToMonetary(option.price)} />
                </div>
              </Link>
            ))}
          </div>
        </div>
        {/* <div className="card flex-1 w-full h-[200px] max-lg:hidden"></div> */}
      </div>
    </div>
  );
};

export default Selection;

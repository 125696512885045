import { createContext, useContext, useState } from "react";

interface UnitBlockProps {
  number: string;
  indicatorColor?: "pink" | "green" | "orange" | "blue";
  className?: string;
  mainTilePosition: "top" | "bottom";
  hasSecondaryTile?: boolean;
  hasMainTile?: boolean;
  tilesPosition: "left" | "right";
}
const UNIT_SIZE = "w-16 h-24 min-w-16 min-h-24";
const STREET_SIZE = `gap-[60px]`;
const translateX = `-translate-x-[69px]`;

//const UNIT_SIZE = "w-7 h-10 min-w-7 min-h-10";
//const STREET_SIZE = `gap-[20px]`;
//const translateX = `-translate-x-[19px]`;

export function UnitBlock({
  number,
  indicatorColor,
  className = "",
  mainTilePosition,
  tilesPosition,
  hasSecondaryTile,
  hasMainTile,
}: UnitBlockProps) {
  const { selectedUnit, setSelectedUnit } = useContext(UnitContext);

  const handleClick = () => {
    setSelectedUnit(number);
  };

  const indicatorColorClass = {
    pink: "bg-pink-500",
    green: "bg-green-500",
    orange: "bg-orange-400",
    blue: "bg-blue-400",
  }[indicatorColor || "pink"];

  return (
    <div
      className={`border border-black ${UNIT_SIZE} flex items-center justify-center relative hover:bg-black hover:text-white text-black cursor-pointer transition-all duration-300 ${
        selectedUnit === number ? "bg-black text-white" : ""
      }`}
      onClick={handleClick}
    >
      {hasMainTile && (
        <div
          className={`w-4 h-8 bg-black absolute ${
            mainTilePosition === "top" ? "top-0" : "bottom-0"
          } ${tilesPosition === "left" ? "left-0" : "right-0"}`}
        />
      )}

      {hasSecondaryTile && (
        <div
          className={`w-7 h-4 bg-black absolute ${
            mainTilePosition === "top" ? "bottom-0" : "top-0"
          } ${tilesPosition === "left" ? "left-0" : "right-0"}`}
        />
      )}

      <span className="text-xl font-bold">{number?.padStart(2, "0")}</span>
      {indicatorColor && (
        <div
          className={`absolute bottom-1 left-1 w-2 h-2 ${indicatorColorClass}`}
        />
      )}
    </div>
  );
}

const MidBlockPair = ({
  hasMainTile,
  hasSecondaryTile,
  mainTilePosition,
  numbers,
}: {
  hasMainTile: boolean;
  hasSecondaryTile: boolean;
  mainTilePosition: "top" | "bottom";
  numbers: string[];
}) => {
  return (
    <div className="flex gap-0.5">
      <UnitBlock
        number={numbers[0]}
        mainTilePosition={mainTilePosition}
        tilesPosition="right"
        hasMainTile={hasMainTile}
        hasSecondaryTile={hasSecondaryTile}
      />
      <UnitBlock
        number={numbers[1]}
        mainTilePosition={mainTilePosition}
        tilesPosition="left"
        hasMainTile={hasMainTile}
        hasSecondaryTile={hasSecondaryTile}
      />
    </div>
  );
};

const FullBlock = ({
  numbers,
  singleUnitPosition,
  mainTilePosition,
  hasSecondaryTile,
}: {
  numbers: string[];
  singleUnitPosition: "first" | "last";
  mainTilePosition: "top" | "bottom";
  hasSecondaryTile: boolean;
}) => {
  return (
    <div className="flex gap-2">
      <div className={`order-${singleUnitPosition === "first" ? 0 : 1}`}>
        <UnitBlock
          number={
            singleUnitPosition === "first"
              ? numbers[0]
              : numbers[numbers.length - 1]
          }
          mainTilePosition={mainTilePosition}
          tilesPosition={singleUnitPosition === "first" ? "left" : "right"}
          hasMainTile={true}
          hasSecondaryTile={false}
        />
      </div>
      <MidBlockPair
        hasMainTile={true}
        hasSecondaryTile={hasSecondaryTile}
        mainTilePosition={mainTilePosition}
        numbers={numbers.slice(1, 3)}
      />
      <MidBlockPair
        hasMainTile={true}
        hasSecondaryTile={hasSecondaryTile}
        mainTilePosition={mainTilePosition}
        numbers={numbers.slice(3, 5)}
      />
      <MidBlockPair
        hasMainTile={true}
        hasSecondaryTile={hasSecondaryTile}
        mainTilePosition={mainTilePosition}
        numbers={numbers.slice(5, 7)}
      />
      <MidBlockPair
        hasMainTile={true}
        hasSecondaryTile={hasSecondaryTile}
        mainTilePosition={mainTilePosition}
        numbers={numbers.slice(7, 9)}
      />
    </div>
  );
};

const getNumbers = (length: number) => {
  return Array.from({ length }, (_, index) => length - index).map((number) =>
    number.toString()
  );
};

interface UnitContextType {
  selectedUnit: string | null;
  setSelectedUnit: (unit: string | null) => void;
}

const UnitContext = createContext<UnitContextType>({
  selectedUnit: null,
  setSelectedUnit: () => {},
});

export default function FloorPlan({
  setSelectedUnit,
  selectedUnit,
}: {
  setSelectedUnit: (unit: string | null) => void;
  selectedUnit: string | null;
}) {
  const numbersLength = 61;

  return (
    <UnitContext.Provider value={{ selectedUnit, setSelectedUnit }}>
      <div className="flex flex-col items-center justify-between">
        <div
          className={`flex flex-col ${STREET_SIZE} items-center justify-center`}
        >
          <div className={`flex ${STREET_SIZE} ${translateX}`}>
            <div className="flex gap-2">
              <p className="text-black text-center tracking-widest text-md mr-10 mt-8">
                ÁREA COMUM
              </p>
              <UnitBlock
                number={getNumbers(numbersLength)[0]}
                mainTilePosition="bottom"
                tilesPosition="left"
                hasMainTile={true}
                hasSecondaryTile={false}
              />
              <MidBlockPair
                hasMainTile={true}
                hasSecondaryTile={false}
                mainTilePosition="bottom"
                numbers={getNumbers(numbersLength).slice(1, 3)}
              />
              <MidBlockPair
                hasMainTile={true}
                hasSecondaryTile={false}
                mainTilePosition="bottom"
                numbers={getNumbers(numbersLength).slice(3, 5)}
              />
            </div>
            <div className="flex gap-2">
              <MidBlockPair
                hasMainTile={true}
                hasSecondaryTile={false}
                mainTilePosition="bottom"
                numbers={getNumbers(numbersLength).slice(5, 7)}
              />
              <UnitBlock
                number={getNumbers(numbersLength)[7]}
                mainTilePosition="bottom"
                tilesPosition="right"
                hasMainTile={true}
                hasSecondaryTile={false}
              />
              <p className="text-black text-center tracking-widest text-md ml-10 mt-8">
                ÁREA COMUM
              </p>
            </div>
          </div>
          <div className={`flex ${STREET_SIZE}`}>
            <FullBlock
              numbers={getNumbers(numbersLength).slice(8, 18)}
              singleUnitPosition="first"
              mainTilePosition="top"
              hasSecondaryTile={false}
            />
            <FullBlock
              numbers={getNumbers(numbersLength).slice(16, 26)}
              singleUnitPosition="last"
              mainTilePosition="top"
              hasSecondaryTile={false}
            />
          </div>
          <div className={`flex ${STREET_SIZE}`}>
            <FullBlock
              numbers={getNumbers(numbersLength).slice(26, 36)}
              singleUnitPosition="first"
              mainTilePosition="bottom"
              hasSecondaryTile={true}
            />
            <FullBlock
              numbers={getNumbers(numbersLength).slice(34, 44)}
              singleUnitPosition="last"
              mainTilePosition="bottom"
              hasSecondaryTile={true}
            />
          </div>
          <div className={`flex ${STREET_SIZE}`}>
            <FullBlock
              numbers={getNumbers(numbersLength).slice(44, 54)}
              singleUnitPosition="first"
              mainTilePosition="top"
              hasSecondaryTile={false}
            />

            <div className="flex gap-2">
              <div className={UNIT_SIZE} />
              <UnitBlock
                number={getNumbers(numbersLength)[53]}
                mainTilePosition="top"
                tilesPosition="left"
                hasMainTile={true}
                hasSecondaryTile={false}
              />
              <MidBlockPair
                hasMainTile={true}
                hasSecondaryTile={false}
                mainTilePosition="top"
                numbers={getNumbers(numbersLength).slice(54, 56)}
              />
              <MidBlockPair
                hasMainTile={true}
                hasSecondaryTile={false}
                mainTilePosition="top"
                numbers={getNumbers(numbersLength).slice(56, 58)}
              />
              <MidBlockPair
                hasMainTile={true}
                hasSecondaryTile={false}
                mainTilePosition="top"
                numbers={getNumbers(numbersLength).slice(58, 60)}
              />
              <UnitBlock
                number={getNumbers(numbersLength)[60]}
                mainTilePosition="top"
                tilesPosition="right"
                hasMainTile={true}
                hasSecondaryTile={false}
              />
            </div>
          </div>
        </div>

        <p className="text-black text-center tracking-widest text-md  mt-10">
          ENTRADA
        </p>
      </div>
    </UnitContext.Provider>
  );
}

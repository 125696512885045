import { House } from "../types/house";

export const houses: House[] = [
  {
    title: "Casa 1",
    size: "900",
    houseSize: "100",
    price: 360000,
    amount: {
      total: 10,
      available: 5,
    },
    id: "1",
    mainInfo: {
      title: "Lançamento",
      label: "02/2024",
    },
    infos: [
      {
        title: "Quartos",
        label: "3",
      },
      {
        title: "Banheiros",
        label: "2",
      },
      {
        title: "Vagas",
        label: "2",
      },
      {
        title: "Cozinha",
        label: "8m²",
      },
      {
        title: "Área de serviço",
        label: "5m²",
      },
      {
        title: "Área de lazer",
        label: "100m²",
      },
      {
        title: "Sacada",
        label: "10m²",
      },
    ],
    images: {
      exterior: [
        "https://images.unsplash.com/photo-1652792997653-7e5897fb309a?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1641975440216-9d8d5409e5d8?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      interior: [
        "https://images.unsplash.com/photo-1640434037438-c3e8485687c2?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1640912744459-0aea6e0b1bd5?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1645237455598-e8f02d706a4e?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      gallery: [
        "https://images.unsplash.com/photo-1507089947368-19c1da9775ae?q=80&w=1752&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1533779283484-8ad4940aa3a8?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1554995207-c18c203602cb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      sphere: "https://files.readme.io/d03acb0-Shperical_o_panoramic.png",
    },
  },
  {
    title: "Casa 2",
    size: "1200",
    houseSize: "150",
    price: 450000,
    id: "2",
    amount: {
      total: 10,
      available: 0,
    },
    mainInfo: {
      title: "Data de Lançamento",
      label: "02/2024",
    },
    infos: [
      {
        title: "Quartos",
        label: "3",
      },
      {
        title: "Banheiros",
        label: "2",
      },
      {
        title: "Vagas",
        label: "2",
      },
      {
        title: "Cozinha",
        label: "8m²",
      },
      {
        title: "Área de serviço",
        label: "5m²",
      },
      {
        title: "Área de lazer",
        label: "100m²",
      },
      {
        title: "Sacada",
        label: "10m²",
      },
    ],
    images: {
      exterior: [
        "https://images.unsplash.com/photo-1652792997653-7e5897fb309a?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1641975440216-9d8d5409e5d8?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      interior: [
        "https://images.unsplash.com/photo-1640434037438-c3e8485687c2?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1640912744459-0aea6e0b1bd5?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1645237455598-e8f02d706a4e?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      gallery: [
        "https://images.unsplash.com/photo-1507089947368-19c1da9775ae?q=80&w=1752&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1533779283484-8ad4940aa3a8?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        "https://images.unsplash.com/photo-1554995207-c18c203602cb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      ],
      sphere: "https://files.readme.io/d03acb0-Shperical_o_panoramic.png",
    },
  },
  // {
  //   title: "Casa 3",
  //   size: "1500",
  //   houseSize: "200",
  //   price: 540000,
  //   id: "3",
  //   amount: {
  //     total: 10,
  //     available: 5,
  //   },
  //   mainInfo: {
  //     title: "Data de Lançamento",
  //     label: "02/2024",
  //   },
  //   infos: [
  //     {
  //       title: "Quartos",
  //       label: "3",
  //     },
  //     {
  //       title: "Banheiros",
  //       label: "2",
  //     },
  //     {
  //       title: "Garagem",
  //       label: "2 vagas",
  //     },
  //     {
  //       title: "Cozinha",
  //       label: "8m²",
  //     },
  //     {
  //       title: "Área de serviço",
  //       label: "5m²",
  //     },
  //     {
  //       title: "Área de lazer",
  //       label: "100m²",
  //     },
  //     {
  //       title: "Sacada",
  //       label: "10m²",
  //     },
  //   ],
  //   images: {
  //     exterior: [
  //       "https://images.unsplash.com/photo-1652792997653-7e5897fb309a?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1641975440216-9d8d5409e5d8?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //     ],
  //     interior: [
  //       "https://images.unsplash.com/photo-1640434037438-c3e8485687c2?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1640912744459-0aea6e0b1bd5?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1645237455598-e8f02d706a4e?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //     ],
  //     gallery: [
  //       "https://images.unsplash.com/photo-1507089947368-19c1da9775ae?q=80&w=1752&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1533779283484-8ad4940aa3a8?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1554995207-c18c203602cb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //     ],
  //   },
  // },
  // {
  //   title: "Casa 4",
  //   size: "1500",
  //   houseSize: "200",
  //   price: 540000,
  //   id: "3",
  //   amount: {
  //     total: 10,
  //     available: 5,
  //   },
  //   mainInfo: {
  //     title: "Data de Lançamento",
  //     label: "02/2024",
  //   },
  //   infos: [
  //     {
  //       title: "Quartos",
  //       label: "3",
  //     },
  //     {
  //       title: "Banheiros",
  //       label: "2",
  //     },
  //     {
  //       title: "Garagem",
  //       label: "2 vagas",
  //     },
  //     {
  //       title: "Cozinha",
  //       label: "8m²",
  //     },
  //     {
  //       title: "Área de serviço",
  //       label: "5m²",
  //     },
  //     {
  //       title: "Área de lazer",
  //       label: "100m²",
  //     },
  //     {
  //       title: "Sacada",
  //       label: "10m²",
  //     },
  //   ],
  //   images: {
  //     exterior: [
  //       "https://images.unsplash.com/photo-1652792997653-7e5897fb309a?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1641975440216-9d8d5409e5d8?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //     ],
  //     interior: [
  //       "https://images.unsplash.com/photo-1640434037438-c3e8485687c2?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1640912744459-0aea6e0b1bd5?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1645237455598-e8f02d706a4e?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //     ],
  //     gallery: [
  //       "https://images.unsplash.com/photo-1507089947368-19c1da9775ae?q=80&w=1752&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1533779283484-8ad4940aa3a8?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1554995207-c18c203602cb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //     ],
  //   },
  // },
  // {
  //   title: "Casa 5",
  //   size: "1500",
  //   houseSize: "200",
  //   price: 540000,
  //   id: "3",
  //   amount: {
  //     total: 10,
  //     available: 5,
  //   },
  //   mainInfo: {
  //     title: "Data de Lançamento",
  //     label: "02/2024",
  //   },
  //   infos: [
  //     {
  //       title: "Quartos",
  //       label: "3",
  //     },
  //     {
  //       title: "Banheiros",
  //       label: "2",
  //     },
  //     {
  //       title: "Garagem",
  //       label: "2 vagas",
  //     },
  //     {
  //       title: "Cozinha",
  //       label: "8m²",
  //     },
  //     {
  //       title: "Área de serviço",
  //       label: "5m²",
  //     },
  //     {
  //       title: "Área de lazer",
  //       label: "100m²",
  //     },
  //     {
  //       title: "Sacada",
  //       label: "10m²",
  //     },
  //   ],
  //   images: {
  //     exterior: [
  //       "https://images.unsplash.com/photo-1652792997653-7e5897fb309a?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1641975440216-9d8d5409e5d8?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //     ],
  //     interior: [
  //       "https://images.unsplash.com/photo-1640434037438-c3e8485687c2?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1640912744459-0aea6e0b1bd5?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1645237455598-e8f02d706a4e?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //     ],
  //     gallery: [
  //       "https://images.unsplash.com/photo-1507089947368-19c1da9775ae?q=80&w=1752&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1533779283484-8ad4940aa3a8?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1554995207-c18c203602cb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //     ],
  //   },
  // },
  // {
  //   title: "Casa 6",
  //   size: "1500",
  //   houseSize: "200",
  //   price: 540000,
  //   id: "3",
  //   amount: {
  //     total: 10,
  //     available: 5,
  //   },
  //   mainInfo: {
  //     title: "Data de Lançamento",
  //     label: "02/2024",
  //   },
  //   infos: [
  //     {
  //       title: "Quartos",
  //       label: "3",
  //     },
  //     {
  //       title: "Banheiros",
  //       label: "2",
  //     },
  //     {
  //       title: "Garagem",
  //       label: "2 vagas",
  //     },
  //     {
  //       title: "Cozinha",
  //       label: "8m²",
  //     },
  //     {
  //       title: "Área de serviço",
  //       label: "5m²",
  //     },
  //     {
  //       title: "Área de lazer",
  //       label: "100m²",
  //     },
  //     {
  //       title: "Sacada",
  //       label: "10m²",
  //     },
  //   ],
  //   images: {
  //     exterior: [
  //       "https://images.unsplash.com/photo-1652792997653-7e5897fb309a?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1641975440216-9d8d5409e5d8?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //     ],
  //     interior: [
  //       "https://images.unsplash.com/photo-1640434037438-c3e8485687c2?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1640912744459-0aea6e0b1bd5?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1645237455598-e8f02d706a4e?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //     ],
  //     gallery: [
  //       "https://images.unsplash.com/photo-1507089947368-19c1da9775ae?q=80&w=1752&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1533779283484-8ad4940aa3a8?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //       "https://images.unsplash.com/photo-1554995207-c18c203602cb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //     ],
  //   },
  // },
];

export const data = [
  {
    id: "header",
    data: {
      title: "Vila de Casas",
      buttonLink: "",
      buttonLabel: "Agende sua visita",
    },
  },
  {
    id: "background-wrapper",
    data: {
      backgroundUrl:
        "https://images.unsplash.com/photo-1652792997653-7e5897fb309a?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      children: [
        {
          id: "hero",
          data: {
            title: "Para você aproveitar a vida sem pressa",
            description:
              "A segurança, qualidade de vida e proximidade com São Paulo. Cotia é \n            uma cidade que respira, sem prédios altos e repleta de atrativos \n naturais",
            primaryButtonLabel: "ESCOLHA SUA CASA",
            primaryButtonLink: "#selection",
            addressText: "Rua João de Barros, 123, Cotia - SP",
          },
        },
      ],
    },
  },
  {
    id: "card-selection",
    data: {
      title: "Confira nossas plantas disponíveis",
      description:
        "As soluções de tecnologia, sustentabilidade e economia, serão sempre acompanhadas em tempo em que virarem necessidades reais. Temos duas plantas incríveis, com metragens entre 150m² e 223m²",
      options: houses,
      htmlId: "selection",
    },
  },
  {
    id: "info-card",
    data: {
      title:
        "Piscina com borda infinita com vista para a mata preservada e hidromassagem.",
      description:
        "Vila Koty é diferente por colocar luxo junto a natureza. Além da harmonia na arquitetura, promove uma cultura de convívio agradável e diversas opções de lazer que v para o seu dia-a-dia.",
      imageUrl:
        "https://images.unsplash.com/photo-1491510736257-3ad769ff47b6?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      imagePosition: "right",
    },
  },
  {
    id: "info-card",
    data: {
      title: "Privacidade para seus eventos",

      description:
        "Um salão de festas com piscina exclusiva de borda infinita, garante aos seus convidados um momento perfeito de usufruir sem invadir a prioridade dos moradores.",
      imageUrl:
        "https://images.unsplash.com/photo-1527529482837-4698179dc6ce?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      imagePosition: "left",
    },
  },
  {
    id: "info-card",
    data: {
      title:
        "Qualidade de vida pode estar muito mais  próxima do que você imagina",
      description:
        "Mudanças extremamente agradáveis que não impactam na sua vida profissional, no futuro da família. O Vila Koty te leva a uma nova perspectiva de vida",
      imageUrl:
        "https://images.unsplash.com/photo-1460788150444-d9dc07fa9dba?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
  },
  {
    id: "gradient-info-card-top",
    data: {
      title: "Sonhe alto, viva bem",
      description:
        "Vila Koty foi feito para solucionar desejos de quem sonha alto. <br/><br/>Quem almeja viver bem sem deixar de lado seus planos.\n \n \n Entre em contato com um de nossos corretores e saiba mais sobre o Vila Koty.",

      imageUrl:
        "https://images.unsplash.com/photo-1588557132645-ff567110cafd?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
  },
  {
    id: "locale",
    data: {
      title: "Localização",
      location: {
        address:
          "https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;coord=52.70967533219885, -8.020019531250002&amp;q=1%20Grafton%20Street%2C%20Dublin%2C%20Ireland&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed",
        options: [
          {
            title: "Padaria Dona Deôla",
            label: 3,
            suffix: "km",
            icon: "IoTrainOutline io5",
          },
          {
            title: "Open Mall The Square",
            label: 3,
            suffix: "km",
            icon: "",
          },
          {
            title: "Parque Cemucam",
            label: 2.5,
            suffix: "km",
            icon: "",
          },
          {
            title: "Shopping Granja Vianna",
            label: 5,
            suffix: "km",
            icon: "",
          },
          {
            title: "Templo Zulai",
            label: 11,
            suffix: "km",
            icon: "",
          },

          {
            title: "Rodoanel Mário Covas",
            label: 7,
            suffix: "km",
            icon: "",
          },
          {
            title: "São Paulo",
            label: 28,
            suffix: "km",
            icon: "",
          },
          {
            title: "Centro de Cotia",
            label: 6,
            suffix: "km",
            icon: "",
          },
        ],
      },
    },
  },
  {
    id: "faq",
    data: {
      title: "Perguntas Frequentes",
      questions: [
        {
          title: "Quando é o lançamento?",
          label:
            "O lançamento será no dia 02/2024. Ainda temos apartamentos disponíveis para reserva.",
        },
        {
          title: "Quais são os prazos de entrega?",
          label:
            "O prazo de entrega é de 36 meses. A entrega será no final de 2026.",
        },
        {
          title: "Quais os diferenciais do projeto?",
          label:
            "O projeto conta com uma ampla área de lazer, piscinas, sauna, SPA, deck, lounge, praça, depósito de garagem e área gourmet.",
        },
        {
          title: "Qual a localização do projeto?",
          label:
            "O projeto está localizado na Rua Doutor João Pessoa, 100 - Jardim São Paulo - Cotia/SP",
        },
        {
          title: "Qual a distância do metrô?",
          label:
            "A estação mais próxima é a Estação Cotia - SPTrans, que fica a aproximadamente 1,5km.",
        },
      ],
    },
  },
];

export const features = [
  {
    title: "piscinas",
    label:
      "2 Piscinas com borda infinita. Coletiva e exclusiva para o salão de festas",
  },
  {
    title: "Sauna",
    label: "Sauna seca e molhada",
  },
  {
    title: "SPA",
    label: "SPA com Hidromassagens",
  },
  {
    title: "Terraço",
    label: "Terraço com vista para o parque",
  },
  {
    title: "Ginásio",
    label: "Ginásio híbrido Beauty care Salão de festas e espaço gourmet",
  },
  {
    title: "Área de espera",
    label: "Área de espera de Uber e vans",
  },
  {
    title: "Deck",
    label: "Deck circulação / cobertura",
  },
  {
    title: "Lounge",
    label: "Lounge com piscina",
  },
  {
    title: "Praça",
    label: "Praça com vista para o parque",
  },
  {
    title: "Depósito",
    label: "Depósito de garagem",
  },
];

import React from "react";

type Props = {
  title: string;
  description: string;
  imageUrl: string;
  imagePosition?: "left" | "right";
};

export const InfoCard = ({
  title,
  description,
  imageUrl,
  imagePosition = "right",
}: Props) => {
  return (
    <div className="relative pb-[100px]">
      <div
        className="w-full h-full z-10 absolute"
        style={{
          backgroundImage: `linear-gradient(to bottom, #000 30%, transparent 100%)`,
        }}
      />
      <div
        className="grid max-w-7xl mx-auto w-full px-4 lg:px-0 py-10 lg:pt-[150px] pb-[250px] lg:pb-[500px] items-center gap-10 bg-center bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url('${imageUrl}'), linear-gradient(#000 0%, #000 100%)`,
        }}
      >
        <div className="text-white flex flex-col lg:flex-row flex-1 gap-10 z-10">
          <h2
            className="text-2xl lg:text-7xl font-bold lg:w-1/2"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <p
            className="description lg:w-1/2"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </div>
  );
};

export default InfoCard;

import React from "react";

import { ComponentBase } from "../../types/ComponentBase";

type Props = {
  data: ComponentBase[];
};

const renderComponent = (id: string, data: any) => {
  switch (id) {
    case "header":
      const Header = require("../Header").default;
      return <Header {...data} />;
    case "hero":
      const Hero = require("../Hero").default;
      return <Hero {...data} />;
    case "locale":
      const Locale = require("../Locale").default;
      return <Locale {...data} />;
    case "selection":
      const Selection = require("../Selection").default;
      return <Selection {...data} />;
    case "faq":
      const Faq = require("../Faq").default;
      return <Faq {...data} />;
    case "background-wrapper":
      const BackgroundWrapper = require("../BackgroundWrapper").default;
      return <BackgroundWrapper {...data} />;
    case "info-card":
      const InfoCard = require("../InfoCard").default;
      return <InfoCard {...data} />;
    case "gradient-info-card":
      const GradientInfoCard = require("../GradientInfoCard").default;
      return <GradientInfoCard {...data} />;
    case "gradient-info-card-top":
      const GradientInfoCardTop = require("../GradientInfoCardTop").default;
      return <GradientInfoCardTop {...data} />;
    case "card-selection":
      const CardSelection = require("../CardSelection").default;
      return <CardSelection {...data} />;
    default:
      return null;
  }
};

const ComponentSelector = ({ data }: Props) => {
  return (
    <>
      {data.map((item, index) => (
        <div key={index}>{renderComponent(item.id, item.data)}</div>
      ))}
    </>
  );
};

export default ComponentSelector;

import { data } from "../../data/houses";
import ComponentSelector from "../../components/ComponentSelector";

export default function Home() {
  return (
    <>
      {/* <video
        className={`z-10 h-screen fixed top-0 left-0 right-0 transition-all duration-700 `}
        style={{
          width: "100%",
          height: "100%",
        }}
        src="https://malinowskiego.com/videos/h265/desktop_large/Malinowskiego_Hero_1-2.mp4"
        preload="auto"
        muted={true}
        loop={false}
        playsInline={false}
        webkit-playsinline={false}
        x5-playsinline={false}
        autoPlay={true}
        disablePictureInPicture={true}
        controlsList="nodownload nofullscreen"
      /> */}
      {/* <div
        className="bg-black bg-fixed bg-cover bg-center relative"
        style={{
          backgroundImage:
            "url('https://images.unsplash.com/photo-1652792997653-7e5897fb309a?q=80&w=2664&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-70" />
        <Hero />
        <Selection id="selection" options={houses} />
      </div> */}
      <ComponentSelector data={data} />
      {/* <Locale /> */}
      {/* <Features /> */}
      {/* <Faq /> */}
    </>
  );
}

import React from "react";
import { FiMinus, FiPlus } from "react-icons/fi";
import { GrPowerReset as ResetIcon } from "react-icons/gr";

import { useControls } from "react-zoom-pan-pinch";

const buttonClassName = "text-white text-sm bg-black rounded-full p-2";

export const ZoomControls = () => {
  const { zoomIn, zoomOut, centerView } = useControls();

  return (
    <div className="tools flex flex-col items-center gap-4">
      <button onClick={() => zoomIn()} className={buttonClassName}>
        <FiPlus size={24} />
      </button>
      <button onClick={() => zoomOut()} className={buttonClassName}>
        <FiMinus size={24} />
      </button>
      <button onClick={() => centerView()} className={buttonClassName}>
        <ResetIcon size={24} />
      </button>
    </div>
  );
};

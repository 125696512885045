import React from "react";

type Props = {
  title: string;
  description: string;
  imageUrl: string;
  imagePosition?: "left" | "right";
};

export const InfoCard = ({
  title,
  description,
  imageUrl,
  imagePosition = "right",
}: Props) => {
  return (
    <div
      className="bg-center bg-cover bg-no-repeat relative"
      style={{
        backgroundImage: `url('${imageUrl}'), linear-gradient(#000 0%, #000 100%)`,
      }}
    >
      <div
        className="w-full h-full z-10 absolute"
        style={{
          backgroundImage: `linear-gradient(to ${imagePosition}, #000 30%, transparent 100%)`,
        }}
      />
      <div className="grid max-w-7xl mx-auto w-full px-4 lg:px-8 py-10 lg:py-[200px] items-center gap-10 lg:grid-cols-2 grid-cols-1">
        <div
          data-order={imagePosition}
          className="text-white flex flex-col lg:flex-row flex-1 gap-10 order-1 lg:data-[order=left]:order-1 z-10"
          // style={{
          //   order: imagePosition === "left" ? 1 : 0,
          // }}
        >
          <h2 className="title-text">{title}</h2>
          <p className="description">{description}</p>
        </div>

        <div
          data-order={imagePosition}
          className="lg:data-[order=right]:order-1 relative"
        ></div>
      </div>
    </div>
  );
};

export default InfoCard;

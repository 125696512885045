import React from "react";
import { House } from "../../types/house";
import { IoIosResize } from "react-icons/io";
import { FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
type Props = {
  title: string;
  description: string;
  options: House[];
  htmlId: string;
};

export const CardSelection = ({
  title,
  description,
  options,
  htmlId,
}: Props) => {
  return (
    <div className="bg-[#F5F5F5]">
      <div
        id={htmlId}
        className="flex flex-col max-w-7xl mx-auto w-full px-4 lg:px-8 py-10 lg:py-[100px] items-center gap-10"
      >
        <div className="flex flex-col gap-4 items-center justify-center">
          <h2 className="title-text text-center">{title}</h2>
          <p className="description text-center">{description}</p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {options.map((option) => {
            const rooms = option.infos.find(
              (info) => info.title === "Quartos"
            )?.label;
            const bathrooms = option.infos.find(
              (info) => info.title === "Banheiros"
            )?.label;
            const garage = option.infos.find(
              (info) => info.title === "Vagas"
            )?.label;
            return (
              <Link
                to={`/house/${option.id}`}
                className="flex flex-col bg-white rounded-xl p-5 hover:scale-105 transition-all duration-300 gap-4"
              >
                <img
                  src={option.images.exterior[0]}
                  alt={option.title}
                  className="rounded-xl w-full h-[300px] object-cover"
                />
                <div className="flex justify-between gap-2 items-center">
                  <div className="flex flex-col gap-2">
                    <p className="text-3xl font-bold">{option.title}</p>
                    <div className="flex gap-2 text-black-600 justify-between items-center">
                      <div className="flex gap-2">
                        <p>{rooms} quartos</p>
                        <p>{bathrooms} banheiros</p>
                        <p>{garage} vagas</p>
                        <p>{option.size}m²</p>
                      </div>
                    </div>
                  </div>
                  <button className="bg-black p-2 text-white text-xs rounded-full">
                    <FiChevronRight size={24} />
                  </button>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CardSelection;

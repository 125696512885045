import React from "react";
import { ButtonHTMLAttributes } from "react";

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  className?: string;
  variant?: "primary" | "secondary";
  outline?: boolean;
};

const variantClasses = {
  primary: {
    default: "bg-white text-black",
    outline: "border border-white text-white",
  },
  secondary: {
    default: "bg-black text-white",
    outline: "border border-black text-black",
  },
};

const Button = ({
  children,
  className,
  variant = "primary",
  outline = false,
  ...props
}: Props) => {
  return (
    <button
      type="button"
      className={`py-2 lg:py-3 px-4 lg:px-6 rounded-3xl uppercase text-xs lg:text-sm font-medium ${className} ${
        variantClasses[variant][outline ? "outline" : "default"]
      }`}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;

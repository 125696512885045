import React from "react";
import ComponentSelector from "../ComponentSelector";
import { ComponentBase } from "../../types/ComponentBase";

type Props = {
  children: ComponentBase[];
  backgroundUrl: string;
};

export const BackgroundWrapper = ({ children, backgroundUrl }: Props) => {
  return (
    <div
      className="bg-black bg-fixed bg-cover bg-center relative"
      style={{
        backgroundImage: `url('${backgroundUrl}')`,
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-70" />
      <ComponentSelector data={children} />
    </div>
  );
};

export default BackgroundWrapper;
